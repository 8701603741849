.css-w4fqad-MuiButtonBase-root-MuiListItemButton-root:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: transparent !important;
  }
  
  .buttonHover:hover {
    background-color: var(--white) !important;
    color: #fff !important;
  }
  
  .buttonHover:hover .Dashicon {
    color: var(--primary-color) !important;
  }
  
  .buttonHover:hover .DashNaVtext {
    color: var(--primary-color) !important;
  }
  
  .user-DashSubManu {
    padding-bottom: 20px;
    width: 90%;
    border-bottom-right-radius: 5px;
  }
  
  .user-DashSubManuChild {
    border-left: 3px solid var(--secondary-color);
    width: 65%;
    margin: auto;
  }
  
  .user-DashSubManuChild a {
    text-decoration: none;
    display: block;
    padding: 2px 10px;
    margin-bottom: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: var(--secondary-color);
    font-size: 14px;
  }
  
  .user-DashSubManu a:hover {
    background-color: var(--secondary-color);
    color: var(--white);
  }
  
  .sideBar-user .active-nav {
    background-color: var(--white);
    color: var(--white);
    text-decoration: none;
    padding: "0px 50px";
    width: 90%;
  }
  
  .sideBar-user .active-nav #NavIcon {
    color: var(--secondary-color) !important;
  }
  
  .sideBar-user .active-nav #NavText {
    color: var(--secondary-color) !important;
  }
  
  .sideBar-user .normal-nav {
    background-color: var(--secondary-color);
    width: 90%;
  }
  