.active-nav {
  background-color: var(--secondary-color);
  color: var(--white);
  transition: all 0.5s ease-in-out;
  text-decoration: none;
  width: fit-content;
  height: 100%;
  border-radius: 5px 5px 0px 0px;
  padding: 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landingActive-nav {
  background-color: var(--secondary-color);
  color: var(--white);
  transition: all 0.5s ease-in-out;
  text-decoration: none;
  width: fit-content;
  height: 100%;
  border-radius: 20px;
  padding: 3px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
}
.landingNormal-nav {
  font-weight: 400;
  text-decoration: none;
  color: var(--primary-color);
}
.normal-nav {
  background-color: var(--primary-color);
  color: var(--white);
  transition: all 0.5s ease-in-out;
  text-decoration: none;
  width: fit-content;
  height: 100%;
  border-radius: 5px 5px 0px 0px;
  padding: 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.active-mobile-nav {
  background-color: var(--secondary-color);
  color: var(--white);
  transition: all 0.5s ease-in-out;
  text-decoration: none;
  width: 100%;
  height: fit-content;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: "5px";
}
.normal-mobile-nav {
  background-color: var(--primary-color);
  color: var(--white);
  transition: all 0.5s ease-in-out;
  text-decoration: none;
  width: 100%;
  height: fit-content;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: "5px";
}
