* {
  font-family: "Poppins" !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins" !important;
}
html {
  scroll-behavior: smooth;
}
a {
  text-decoration: none !important;
}
p {
  margin: 0;
  padding: 0;
  font-family: "Poppins" !important;
}
:root {
  --primary-color: #ff5500;
  --secondary-color: #222222;
  --gray: #9c9797;
  --fontcolor: #6a6a6a;
  --drawer-bgcolor: #f3f5f7;
  --white: #ffffff;
  --transit: #d3e0f0;
  --light-bg: #d3e0f0;
  --light-gray: #dddddd;
  --btn-bg: #525371;
  --tab-bg: #ffe5ca;
  --search-bar-bg: rgba(31, 27, 27, 0.43);
  --input-bgcolor: #f4f4f4;
  /* used the above Color */
  --yello-color: #f69731;
  --third-color: #9c9797;
  --red: #ff0000;
  --black: #000;
  --sabre: #ff0000;
  --galileo: #ffffff;
  --flyhub: #4169e1;
  --mateBlack: #222222;
  --tomato-color: #ff4a4a;
  --text-greencolor: #f69731;
  --dark-greencolor: #0d3a26;
  --total-text-color: #f60d0d;
  --landing-page-circle: rgba(246, 151, 49, 0.39);
}

button.shine-effect {
  overflow: hidden;
}

button.shine-effect::after {
  content: "";
  width: 30px;
  height: 200px;
  position: absolute;
  left: -20px;
  top: 0;
  margin-top: -20px;
  background: var(--secondary-color);
  opacity: 0.6;
  filter: blur(20px);
  animation: shine 4s infinite;
  animation-delay: 0s;
  transform-origin: top;
}
@keyframes shine {
  from {
    transform: translate(0) rotate(45deg);
  }
  to {
    transform: translateX(120vh) rotate(45deg);
  }
}
.customPlaceholder::placeholder {
  color: var(--secondary-color) !important ;
}

.sabre {
  /* border: 2px solid var(--sabre); */
  border: 2px solid var(--white);
  border-radius: 100%;
  /* height: 50px;
  width: 50px; */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  /* object-fit: cover; */
}
.galileo {
  /* border: 2px solid var(--galileo); */
  border: 2px solid var(--white);
  border-radius: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.flyhub {
  border: 2px solid var(--flyhub);
  /* border: 2px solid var(--white); */
  border-radius: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.dashboard-calendar {
  position: absolute;
  top: 105%;
  left: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  z-index: 999;
}

.new-return-date-range {
  position: absolute;
  top: 105%;
  right: 25px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  z-index: 10;
}
.new-return-date-range .rdrDateDisplayWrapper,
.new-return-date-mobile .rdrDateDisplayWrapper {
  display: none;
}

@media all and (max-width: 600px) {
  .dashboard-calendar {
    position: absolute;
    top: 100%;
    left: -20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 5px;
    z-index: 10;
  }
  .new-return-date-range {
    position: absolute;
    width: 92vw;
    top: 100%;
    left: -10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 5px;
    z-index: 10;
  }
  .new-return-date-range .rdrDateDisplayWrapper,
  .new-return-date-mobile .rdrDateDisplayWrapper {
    display: none;
  }
}

/*//todo: CSS for all */
.balance-transaction table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 5px;
  text-align: center;
}

.balance-transaction table tr th {
  background-color: var(--secondary-color);
  color: #fff;
  padding: 8px 0px;
  font-weight: 300;
  font-size: 12px;
  text-align: center;
}

.balance-transaction table td,
th {
  text-align: left;
  padding: 8px;
  text-align: center;
}

.balance-transaction table td {
  color: #252733;
  font-size: 12px;
}

.balance-transaction table tr:hover {
  background-color: #e0ecfb !important;
}

.passengerInput1 p {
  color: #ffa84d;
  font-family: "Poppins";
  font-style: normal;
  font-size: 13px;
  font-weight: 500;
}

.passengerInput1 input {
  border: 0px;
  border-bottom: 1px solid #2d669b;
  width: 100%;
  padding-bottom: 5px;
  color: #2564b8;
}

.passengerInput1 select {
  border: 0px;
  border-bottom: 1px solid #2d669b;
  width: 100%;
  /* padding-bottom: 5px; */
  color: #2564b8;
}

.passengerInput1 input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #2d669b;
  opacity: 1; /* Firefox */
  font-size: 11px;
  font-family: "Poppins";
}

.passengerInput1 input:focus {
  outline: none;
}
.passengerInput1 select:focus {
  outline: none;
}
.hold-btn {
  padding: 5px;
  color: #003566;
}
.issue-in-processing-btn {
  padding: 5px;
  color: #dc143c;
}
.issue-rejected-btn {
  padding: 5px;
  color: #aaac2b;
}
.ticketed-btn {
  padding: 5px;
  color: #089326;
}

.void-in-processing-btn {
  padding: 5px;
  color: #dc14a4;
}
.voided-btn {
  padding: 5px;
  color: #21a412;
}
.void-rejected-btn {
  padding: 5px;
  color: #21a412;
}
.reissue-in-processing-btn {
  padding: 5px;
  color: #9747ff;
}
.reissued-btn {
  padding: 5px;
  color: #ff6b00;
}
.reissue-rejected-btn {
  padding: 5px;
  color: #ff6b00;
}
.refund-in-processing-btn {
  padding: 5px;
  color: #8a5138;
}

.refunded-btn {
  padding: 5px;
  color: #ff6b00;
}
.refund-rejected-btn {
  padding: 5px;
  color: #ff6b00;
}
.return-btn {
  padding: 5px;
  color: #ff6b00;
}
.cancelled-btn {
  padding: 5px;
  color: #70a5d8;
}
.pending-btn {
  padding: 5px;
  color: #003566;
}
.approved-btn {
  padding: 5px;
  color: #21a412;
}
.rejected-btn {
  padding: 5px;
  color: #dc143c;
}

.new-dashboard-calendar {
  position: absolute;
  top: 100%;
  left: 0px;
  border-radius: 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  z-index: 1000;
  text-align: center;
}

.new-dashboard-calendar .rdrMonth,
.return-dashboard-calendar .rdrMonth {
  padding: 5px;
}
.new-dashboard-calendar span,
.return-dashboard-calendar span {
  font-size: 14px;
  font-weight: bold;
}
.new-dashboard-calendar span.rdrWeekDay,
.return-dashboard-calendar span.rdrWeekDay {
  color: #e30431;
}
.new-dashboard-calendar .rdrMonthName,
.return-dashboard-calendar .rdrMonthName {
  color: #003566;
}

.new-dashboard-calendar .rdrMonthAndYearWrapper,
.return-dashboard-calendar .rdrMonthAndYearWrapper {
  align-items: center;
  height: 30px;
  padding-top: 0px;
}
.pdf-ancortag a{
  text-decoration: none !important;
}



/*  Quedetaisl Table------------- */

.flight-queue-detail table {
  width: 100%;
  border-collapse: collapse;
}
.flight-queue-detail table th {
  font-family: "Poppins";
  background: #222222;
  border: none !important;
  text-align: left;
  font-weight: 400;
  font-size: 13px;
  color: #fff;
  padding: 8px;
}
.flight-queue-detail table tr {
  
  border: none !important;
  margin-bottom: 100px;
}
.flight-queue-detail table td {
  padding: 8px;
  font-size: 12px;
  border: 1px solid #d1e9ff;
  text-align: left;
  vertical-align: top;
}
.table-data tr td{
  font-weight: 500;
  font-size: 12px;
}

/*  Queudetails  Table------------- */
/*  Responsive Table------------- */

.table-wrapper {
	overflow-y: hidden;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
}

.responsive-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
	table-layout: fixed;
  border: 1px solid #ddd;
  table-layout: fixed;
}

.responsive-table th,
.responsive-table td {
  text-align: left;
  padding: 5px ;
  font-size: 10px;
  border: 1px solid #ddd;
  font-weight: 500;
}
.responsive-table th {
  padding: 10px 5px;
  background-color: var(--primary-color);
  color:#fff;
  font-weight: 500;
}
.congra th {
  padding: 10px 5px;
  background-color: var(--secondary-color);
  color:#fff;
  font-weight: 500;
}
.responsive-table tr:nth-child(even){background-color: #f2f2f2}



.table-wrapper::-webkit-scrollbar {
  width: 10px;
}
.table-wrapper::-webkit-scrollbar:horizontal {
  height: 10px;
}
.table-wrapper::-webkit-scrollbar-track {
  background-color: transparentize(#ccc, 0.7);
}
.table-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: transparentize(#ccc, 0.5);
  box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}

@media only screen and (max-width: 900px) {
  .table-wrapper {
    width:100vw;
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  .responsive-table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 1000px;
    table-layout: fixed;
    border: 1px solid #ddd;
    table-layout: fixed;
  }

  .flight-queue-detail table th {
    font-size: 9px;
    font-weight: 500;
  }
  .flight-queue-detail table td {
    font-size: 9px;
    font-weight: 500;

  }
}
