.landing-nav {
  text-decoration: none;
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: var(--white);
}
.landing-nav-active {
  text-decoration: none;
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: var(--white);
  gap: 5px;
}
.landing-nav-active::before {
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: var(--secondary-color);
}
.landing-mobile-nav {
  text-decoration: none;
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: var(--primary-color);
}
.landing-mobile-nav-active {
  text-decoration: none;
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: var(--primary-color);
  gap: 5px;
}
.landing-mobile-nav-active::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--secondary-color);
}
